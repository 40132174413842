<template>
  <b-overlay :show="isLoading">
    <b-card :header="title">
      <div
        v-for="(item, key) in items"
        :key="key"
        class="media border-bottom pb-3"
        :class="key !== 0? 'pt-3': ''"
      >
        <div class="media-body">
          <span class="text-muted">{{ item.title }}</span>
          <h4 class="mt-0 mb-1">{{
            item.value
        }}
            <small
              v-if="item.unit"
              class="font-size-12 font-weight-normal"
            >
              {{item.unit}}
            </small>
          </h4>
        </div>
        <feather
          :type="item.icon"
          class="icon-dual icon-md align-self-center"
        ></feather>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Overview',
    },
    items: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
